import React, { useEffect } from "react";

//To change the styling of the donation popup, go to DonatePopUp.css, the id and class names should be pretty self-explanatory.
import "./DonatePopUp.css";

//Donation Pop up component that can lead the user to the donation page.
export default function DonatePopUp({
    popUpDelay = 5000 //This is the time delay before the pop up shows up, default is 5 seconds
}) {
    useEffect(
        () => setTimeout(() => showPopUp(), popUpDelay), []
    )
    
    return (
        <div id="Donation-Pop-Up" style={{display: "none", transitionDuration: "0.4s", opacity: "0",}}>
            <div id="Page-Dark-Cover"></div>
            <div id="Donation-Pop-Up-Container">
            <p id="Donation-Pop-Up-Text">
                Hi there! We would greatly appreciate it if you could consider supporting our work by donating. Any amount helps!
            </p>
            <button id="Consider-Donate-Button" className="buttons" onClick={() => jumpToDonation()}>
                I'll Consider It!
            </button>
            <button id="Close-Donation-Pop-Up-Button" className="buttons" onClick={() => closePopUp()}>
                No, Thanks!
            </button>
            </div>
        </div>
    );
}
//Changes the css display attribute of thge donation pop up, to reveal it.
function showPopUp() {
    document.getElementById("Donation-Pop-Up").style.display = "block";
    setTimeout(function() {document.getElementById("Donation-Pop-Up").style.opacity = "1";}, 400);
    
}
//Closes the pop up
function closePopUp() {
    document.getElementById("Donation-Pop-Up").style.opacity = "0";
    setTimeout(function() {document.getElementById("Donation-Pop-Up").style.display = "none";}, 500);
}
//Takes the user to the donation page once clicked.
function jumpToDonation() {
    closePopUp();
    setTimeout(function() {window.location.href = "./Donate";}, 1000);
}