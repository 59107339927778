import React, { useEffect } from "react";
import './Partners.css';

import Partner_Background from "../../images/backgrounds/Partners_Background.jpg";
import Partner_Currency_Cloud from "../../images/partners/Partner_Currency_Cloud.png";
import Partner_MFS from "../../images/partners/Partner_MFS.jpg";
import Partner_GSE from "../../images/partners/Partner_GSE.jpg";
import Partner_RemTech from "../../images/partners/Partner_RemTech.png";

import AOS from "aos";
import "aos/dist/aos.css";

export default function Partners() {

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className='Partners'>
        <div className='ParterTopSection' style={{ backgroundImage: `url(${Partner_Background})`, width: "100%"  }}>
          <div className='PartnerHeading'>
            As recognized by
          </div>
          <div className='PartnerSection'>
            <div className='PartnerEntry'>
              <img className="PartnerImage" src={Partner_Currency_Cloud} alt=""/>
            </div>
            <div className='PartnerEntry'>
              <img className="PartnerImage" src={Partner_MFS} alt=""/>
            </div>
            <div className='PartnerEntry'>
              <img className="PartnerImage" src={Partner_GSE} alt=""/>
            </div>
            <div className='PartnerEntry'>
              <img className="PartnerImage" src={Partner_RemTech} alt=""/>
            </div>
        </div>
        </div>
    </div>
  );
}
