import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { TextField } from "@mui/material";
import { useState } from "react";
import * as Constants from "../constants";
// const ADMZip = require("adm-zip");

let fileRef;
export default function Dashboard() {    
    document.title = "Shoptaki AI - Dashboard"
    let [checked, changeCheck] = useState(checkboxData.map(a => { return {[a.id]: false} }))
    let updateChecks = (e, id) => {
        changeCheck(old => {
            return {
                ...old,
                [id]: e.target.checked
            }
        })
    }
    return (
        <div style={{ display: "flex", flexDirection: "column", padding: "0px 40px 20px 40px", gap: "10px" }}>
            <h1>Data AI Agent</h1>
            <h3>A sophisticated algorithm to automate data preprocessing, and basic visualization, so data scientists can focus on what matters most.</h3>
            <p>Enter a brief description of the purpose of your dataset here. Be specific as possible.</p>
            <TextField variant="outlined" multiline rows={5} label="Description" />
            <Button variant="filled" style={{ backgroundColor: "blue", color: "white", borderRadius: "30px", margin: "0px auto", padding: "5px 40px" }} component="label">
                Upload your dataset here
                <input
                    hidden
                    accept=".csv"
                    ref={ref => fileRef = ref}
                    type="file" />
            </Button>
            {checkboxData.map(checkbox => 
                <FormControlLabel 
                    control={<Checkbox key={checkbox.id} checked={checked[checkbox.id]} onChange={e => updateChecks(e, checkbox.id)}/>} 
                    key={checkbox.id}
                    label={checkbox.name} 
                    />
                )}
            <Button style={{ backgroundColor: "blue", color: "white", borderRadius: "30px", margin: "0px auto", padding: "5px 40px" }} onClick={sendData}>
                Submit
            </Button>
        </div>
    )

    function sendData() {
        let data = new FormData()
        checkboxData.map(a => {return {[a.id]: checked[a.id] ? "on" : "off"}}).forEach(a => data.append(Object.keys(a)[0], Object.values(a)[0]))
        data.append("dataset", fileRef.files[0])
        for (var pair of data.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
        }
        fetch(`${Constants.BACKEND_API_URL}/preprocess`, {
            method: "POST",
            body: data
        })
        .then(zipAsync => {
            if(zipAsync.ok) {
                return zipAsync.blob()
            }
            else {
                console.log("response", zipAsync)
            }
        })
        .catch(e => {
            console.error(e)
        })
        .then(zipBuffer => {
            // saveAs(zipBuffer, "idk.zip")
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display:none";
            var url = window.URL.createObjectURL(zipBuffer);
            a.href = url;
            a.download = "file.zip";
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();

            // console.log("buffer", zipBuffer)
            // const adm = new ADMZip(zipBuffer)
            // adm.test()
            // console.log(adm)
            // console.log("Entries", adm.getEntries())
        })
        
    }
}

const checkboxData = [
    {
        name: "Fill empty cells with 'NaN'",
        id: "fill_empty_with_nan"
    },
    {
        name: "Remove rows or columns with missing data",
        id: "drop_missing_data"
    },
    {
        name: "Remove outliers",
        id: "remove_outliers"
    },
    {
        name: "Normalize data",
        id: "normalize"
    },
    {
        name: "Delete invariant columns",
        id: "delete_constant_columns"
    },
    {
        name: "Combine First and Last Name Rows/Columns",
        id: "combine_first_last_name"
    },
    {
        name: "Drop useless columns",
        id: "drop_useless_columns"
    },
    {
        name: "Check if dateTime",
        id: "check_if_dateTime"
    },
    {
        name: "One hot encode",
        id: "one_hot_encode"
    }
]

