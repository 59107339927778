import ErrorIcon from '@material-ui/icons/Error';
import { IconButton } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';

export default function ErrorMessage({ error, showError, canDismiss = true, message }) {
  if (!error) {
    return null
  }
  else return (
    <div style={
      {
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "center",
        verticalAlign: "middle",
        borderRadius: "8px",
        backgroundColor: "#ff7c73",
        padding: "5px",
        margin: "20px 0",
        color: "white",
      }}>
      <ErrorIcon style={{ color: "white", margin: "5px" }} />
      <div style={{ padding: "10px" }}>
        <b>{message}</b>
      </div>

      {canDismiss ? <IconButton onClick={() => { showError({ ...error, show: false }) }}>
        <CancelIcon color="white"/>
      </IconButton> : null}
    </div>)
}