import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from 'react-router-dom'
import { createTheme, ThemeProvider } from "@material-ui/core";
import { createContext, useState } from "react";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC_2j9AsU0uj-MaEv_HDBQeo4Qh4Sa01xc",
  authDomain: "prj-prod-smartchainai-7378.firebaseapp.com",
  projectId: "prj-prod-smartchainai-7378",
  storageBucket: "prj-prod-smartchainai-7378.appspot.com",
  messagingSenderId: "166109971897",
  appId: "1:166109971897:web:802995ffcdf4c4995aa268",
  measurementId: "G-YDKD9K8BK5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const theme = createTheme({
  palette: {
    primary: {
      main: "#233D99",
      light: "#00A4E7",
      dark: "#233D99",
      contrastText: "#000"
    },
    secondary: {
      main: "#00A4E7",
      light: "#00A4E7",
      dark: "#EB6308",
      contrastText: "#fff"
    },
    action: {
      disabledBackground: "#d1d5d8",
      disabled: "#f3f4f5"
    }
  }
});

export let LoginContext = createContext({
  authJWT: null,
  setAuth: (jwt) => {}
})
function LoginContextWrapper() {  

  let [auth, setAuth] = useState(null)
  return (
    <LoginContext.Provider authJWT={auth} setAuth={setAuth}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </LoginContext.Provider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme} >
      <LoginContextWrapper />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
