import { Button, TextField } from "@material-ui/core";
import React, { Fragment, useContext, useState } from "react";

import ErrorMessage from "./ErrorMessage";
import VerificationInput from 'react-verification-input';
import './VerificationInputStyling.css'
import { LoginContext } from "../..";
import * as Constants from "../../constants";

export let [formHidden, hideForm] = []
let otpKey
let auth

export default function OTPForm() {
  [formHidden, hideForm] = useState(false)
  let [error, showError] = useState({ show: false, message: "An error has occurred. Please try again later." })
  let [emailText, setEmailText] = useState("")

  return (
    <Fragment>
      <form style={formHidden ? { display: "none" } : { margin: "20px" }} >
        <TextField id="login-email" label="E-Mail" value={emailText} onChange={(event) => { setEmailText(event.target.value) }} />
        <br />
        <br />

        <ErrorMessage error={error.show} canDismiss={false} message={error.message} />

        <Button
          onClick={() => sendOtp(emailText, showError)}
          style={{ backgroundColor: "#fdbf18", borderRadius: "18px", paddingLeft: "14px", paddingRight: "14px" }}
          disabled={emailText.trim() === ""}
        >
          <b>Request Code</b>
        </Button>
      </form>

      {formHidden ? <VerificationStage emailText={emailText} /> : null}

    </Fragment>
  );

  /*
    Error codes for the OTP API:
      400: Missing param "typeName"
      401: OTP expired or is incorrect
      403: Permission denied OR collection DNE
      404: Email is not in the database
      409: User/organization already exists (in signup) or OTP already exists
  */


}

export function sendOtp(rawEmail, showError) {
  const otpEndpoint = encodeURI(`${Constants.LOGIN_API_URL}/api/auth/requestOTP/`)

  fetch(otpEndpoint, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email: rawEmail,
      phone_number: ""
    }),
    redirect: 'follow'
  }).then(res => {
    if (!res.ok) {
      errorCodes(res.status, showError)
      return
    }
    showError({ show: false })
    res.json().then((json) => {
      console.log(json)
      otpKey = json["key"]
      hideForm(true)
    })

  }).catch((error) => console.log(error))
}

export function VerificationStage({ emailText }) {
  const otpLength = 5
  return (
    <div style={{ padding: "10px" }}>
      A one time password (OTP) code has just been sent to the email you entered. Enter that code here to confirm your email.
      <div style={{ padding: "20px" }} />
      <VerificationInput
        removeDefaultStyles
        classNames={{
          container: "container",
          character: "character",
          characterSelected: "character--selected",
          characterInactive: "character--inactive"
        }}
        validChars="0-9"
        length={otpLength}
        autoFocus={true}
        placeholder=''
        onChange={(code) => { if (code.length === otpLength) onOTPFilled(code) }} />

    </div>
  )

  function onOTPFilled(otpCode) {
    let verifyEndpoint = encodeURI(`${Constants.LOGIN_API_URL}/api/auth/verifyOTP`)
    if (otpKey) {
      fetch(verifyEndpoint, {
        mode: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: {
          otp: otpCode,
          key: otpKey
        }
      }).then(res => {
        if (!res.ok) {
          errorCodes(res.status)
          console.log("incorrect otp")
          return
        }
        console.log("Login successful!!")
        return res.json()
      }).then(resJson => {
          if(resJson.payload.token) {
            login(otpCode, otpKey)
          }
      })
    }
  }
}

function login(code, key) {
  let loginEndpoint = encodeURI(`${Constants.LOGIN_API_URL}/api/auth/login`)
  fetch(loginEndpoint, {
    mode: "POST",
    body: {
      otp: code,
      key: key
    }
  })
  .then(res => {
    if(!res.ok) {
      console.error("Something went wrong while attempting to login.")
      return 
    }
    return res.json()
  }).then(json => {
      let jwt = json["payload"]["token"]
      auth = useContext(LoginContext)
      
  })
}

export function errorCodes(err, showError) {
  let errMessage = ""
  switch (err) {
    case 401:
      errMessage = "The code you entered has either expired or is incorrect."
      break

    case 404:
      errMessage = "The email you entered hasn't been found. Please check you have entered it correctly or create an account if you haven't already."
      break

    case 409:
      errMessage = "You already have an OTP code sent to you. Please use the one you were already given. If you need a new code, the previous code will expire 5 minutes after its creation."
      break

    default:
      errMessage = "Sorry, an error has occurred on our end. Please try again later."
  }
  showError({ show: true, message: errMessage })
}

