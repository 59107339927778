import "./App.css";
import Header from "./main-comps/Header";
import Footer from "./main-comps/Footer";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import Donate from "./pages/Donate.js";
import Home from "./pages/Home/Home.js";
import Dashboard from "./pages/Dashboard.js";
import { Divider } from "@material-ui/core";
import LoginModal from "./main-comps/Login/LoginModal";
import SignUp from "./pages/SignUp";
import About from "./pages/About";
// import { createContext } from "react";

function App() {
  document.title = "Shoptaki AI"
  let location = useLocation()
  let background = location.state && location.state.background

  return (
      <div className="App">
        <Header />
        <Divider />
        <div className="content-container">
          <Switch location={background || location}>
            <Route path="/" exact component={Home} />
            <Route path="/about" exact component={About} />
            <Route path="/donate" exact component={Donate} />
            <Route path="/login" component={LoginModal} />
            <Route path="/signup" exact render={() => <SignUp />} />
            <PrivateRoute path="/dashboard" exact component={Dashboard} />
          </Switch>
          {background && <Route path="/login" children={<LoginModal />} />}
        </div>
        <Footer />
      </div>
  );
}

function PrivateRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={
        ({ location }) => (
          isAuthenticated
            ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location }
                }}
              />
            ))
      }
    />
  );
}

export default App;
