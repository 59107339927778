import Webcam from 'react-webcam'
import {InputAdornment, TextField} from '@material-ui/core'
import { useState } from 'react'
import ErrorMessage from './ErrorMessage'

export default function BiometricAuthForm() {
    let [phoneNum, setPhoneNumber] = useState("") 
    let [error, showError] = useState({ show: false, message: "An error has occurred. Please try again later. " })

    return (
        <div style={{margin: "20px", display: "flex", flexDirection: "column"}}>
            <Webcam style={{borderRadius: "10px", margin: "10px"}}/>
            <TextField 
                label="Phone Number (Include country code)" 
                variant="outlined" 
                style={{margin: "10px"}}
                value={phoneNum}
                onChange={(event) => phoneInputChanged(event)}
                // type="number"
                startAdornment={<InputAdornment position="start">+</InputAdornment>}
                />

            {error.show ? <ErrorMessage canDismiss={false} message={error.message} /> : null}
        </div>
    )
    function phoneInputChanged(event) {
        setPhoneNumber(event.target.value.replace(/[^0-9]/g, ''))
        if(phoneNum.length > 9) {
            submitInfo()
        } 
    }

    function submitInfo() {
        let baseUrl = 'https://3.83.158.115:4002'
        let endpoint = encodeURI(`${baseUrl}/somePath`)
        fetch(endpoint, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: {
                "phone_number": phoneNum,
                "media0": null // Need logic for turning webcam image into base64 to post
            }
        }).then((res) => {
            if(!res.ok) {
                showError({show: true, message: "idk bro"})
                return
            }
            showError({show: false, ...error})
        })
    }
}