import React from "react";
import Logo from "./Logo";
import './Header.css'
import { Link, useLocation } from "react-router-dom";
import LoginModal from "./Login/LoginModal";
import { loginOpen, setLoginShow } from './Login/LoginModal';

export default function Header() {
  const location = useLocation();

  return (
    <div className='Header'>
      <LoginModal loginShow={loginOpen} setLoginShow={setLoginShow}/>
      <div className='NavbarLogo'>
        <Logo />
      </div>
      {/* {small ? ( */}

        
        <div className='NavbarLinks'>
          
          <Link to="/about">
            <button className='NavbarButton'>
              <div className='NavbarText'>
                ABOUT US
              </div>
            </button>
          </Link>

          <Link to="/donate">
            <button className='NavbarButton'>
              <div className='NavbarText'>
                DONATE
              </div>
            </button>
          </Link>

          <Link to={{pathName: "/login", state: {background: location}}}>
            <button
              className='NavbarButton'
              onClick={() => setLoginShow(true)}>
              <div className='NavbarText'>
                LOG IN
              </div>
            </button>
            
          </Link>

          <Link to={"/signup"}>
            <button className='NavbarButton'>
              <div className='NavbarText'>
                SIGN UP
              </div>
            </button>
          </Link>

          <Link to="/dashboard">
            <button className='NavbarButton'>
              <div className='NavbarText'>
                DASHBOARD
              </div>
            </button>
          </Link>

        </div>

      {/* ) : (
        <IconButton edge="start" color="inherit" aria-label="menu">
          <MdMenu />
        </IconButton>
      )} */}
  </div>
  );
}
