import React, { useState, useEffect } from "react";
import { CryptoDonateSection } from "./CryptoDonateSection";

import PaypalCardLogo from "../images/paypal-card-logos.png";
import PaypalLogo from "../images/paypal-logo.svg";
import CoolView from "../images/Cool-View.jpg";
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';

import AOS from "aos";
import "aos/dist/aos.css";

import "./Donate.css";
import { Card, CardContent } from "@material-ui/core";

export default function Donate() {

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
  <div className="Donate">
    <br /> <br /> <br />
    <div className="Heading" data-aos="fade-in">
      Support Us
    </div>
    <br /> <br /> <br /> <br /> 
    <div className="SubHeading" data-aos="fade-in">
      <p style={{ margin: "auto", fontSize: "20px" }}>
        Because we need the funds to be able to support the great team we have and also hire full-time teammates with the qualified skills to make this ambitious project a reality, we seek donations of any quantity. Our goal is to raise up to $25 million.
      </p>
    </div>

    {/* <div className="CoolViewImage" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="linear">
      <img src={CoolView} height="900" align="center" margin="auto"/>
    </div> */}

    <br />

    <div className="PayPalDonationSection">
      <div className="TextBoxPayPalDonate" data-aos="fade-right">
        <form id="Paypal-Donation" action="https://www.paypal.com/donate" method="post" target="_top" align="center" style={{ height: "1px" }}>
          <input type="hidden" name="business" value="asnee@shoptaki.com" />
          <input type="hidden" name="no_recurring" value="0" />
          <input type="hidden" name="currency_code" value="USD" />
          <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
        </form>
        <button id="Donate-Button" type="submit" form="Paypal-Donation" value="Donate">
          <span id="Donate-Button-Normal-Content">
            <b>Donate with</b> <img src={PaypalLogo} alt="" style={{ height: "30px", transform: "translateY(25%)" }} />
          </span>
        </button> <br />
        <img src={PaypalCardLogo} alt="Accepted Cards Logo" height="80px" margin="400px" />
      </div>
    </div>

    <br /> <br /> <br /> <br />

    <div className="TextBoxPayCryptoDonate" data-aos="fade-left">
      <CryptoDonateSection />
    </div>

    <br /> <br /> <br /> <br />

    <div id="Second-Description-Section">
      <div className="TextBoxSubSection" data-aos="fade-right">
        <h1 style={{ textAlign: "left", fontSize: "200%" }}>Our Pledge</h1>
        If we are able to reach our funding goal, we will have this site up and running as long as we are able to financially sustain the amount of users benefiting from our technology.        
        <br /> <br />
        <h1 style={{ textAlign: "left", fontSize: "200%"}}>Efficient, Streamlined Healthcare</h1>
        We pledge to give our Data AI agents freely to the entire medical industry. While we may charge a one-time fee per country based on country demographics (i.e. GDP),
        we want to be make our technology as accessible as possible. With Smartchain handling user information in the safest way possible, extensions to healthcare are <b>endless</b>.
        In the medical and healthcare sector, this network enables a seamless exchange of information across devices and providers. With an approximate annual <b>$30 billion</b> healthcare
        transactions consisting of faxing patient information between medical institutions, this represents a significant cost-saving opportunity. Through rapid information exchanges on
        the Smartchain platform, Shoptaki can help spearhead the data integration revolution, helping curb the massive yearly <b>$250 billion</b> expenditure on basic healthcare transactions.
      </div>
      <br /><br />
      <div className="TextBoxSubSection" data-aos="fade-right">
        <h1 style={{ textAlign: "left", fontSize: "200%"}}>Security</h1>
        Smartchain enables standardization of consumer health care, such that medical providers are easily able to gather patient information on the platform and assign them
        to treatment pathways. This eliminates the delay in treating patients, by reducing the explicit and implicit costs of information exchange and referral delays across different
        medical providers. This network promises to be a secure system, in ensuring that users can restrict access to medical data. Such safeguards will curb the <b>4+ billion</b> dollars
        worth of recovery efforts on hacked and compromised patient data in the medical sector.
        <br /> <br />
        With Smartchain, Shoptaki hopes to not only shift the focus of healthcare back towards treating consumers, by eliminating administrative and financial delays in treating
        patients, but also save healthcare companies billions of dollars in spending.
      </div>


    </div>

    <br /> <br /> <br />

    <FAQs />

    <br /> <br /> <br />

  </div>
  )
}

function FAQs() {
  return (
    <div
      className="TextBoxSubSection"
      data-aos="fade-left"
      style={{ paddingTop: "20px", paddingBottom: "20px" }}>
        <div className='FAQTitle'>
          FAQ
        </div>
        {faqTexts.faqs.map((a, index) => <QuestionItem key={index} index={index} />)}
    </div>
  )
}

function QuestionItem({ index }) {
  let [opened, setOpened] = useState(false)
  return (
    <div>
      <QuestionHeader question={faqTexts.faqs[index]["q"]} />
      <QuestionBody answer={faqTexts.faqs[index]["a"]} />
    </div>
  )

  function QuestionHeader({ question }) {
    
    return (
      <button
        className='FAQEntry'
        style={{ background: "none", border: "none", textAlign: "left", cursor: "pointer", width: "100%", paddingLeft: "5px"}}
        onClick={() => { setOpened(prevState => !prevState) }}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          {opened ? 
          <ArrowUpIcon fontSize="large" style={{margin: "10px"}}/> : 
          <ArrowDownIcon fontSize="large" style={{margin: "10px"}}/>}
          <h3 style={{ textAlign: "left", fontSize: "170%" }}>
            <b>{question}</b>
          </h3>
        </div>
      </button>
    )
  }

  function QuestionBody({ answer }) {
    return (
      opened &&
      <div style={{ padding: "0px 60px 30px 60px", textAlign: "left", fontSize: "115%" }}>
        {answer}
      </div>
    )
  }
}


let faqTexts = {
  "faqs": [
    {
      q: "Why are we seeking donations instead of investment directly?",
      a: "While we are actively seeking funding through the usual seed/series rounds, this form of funding requires proof of revenue figures which we don’t have. This is a highly ambitious project that some investors may not have risk tolerance for. Most investors are profit-driven and question why we wouldn’t monetize healthcare. This is because the COVID-19 pandemic has introduced economic turmoil on a global scale. When it comes to healthcare, we believe individuals have the right to accurate and efficient treatment without potential risks. What if we were eventually able to have AI improve healthcare and allocate optimal treatments? Much like a neural network, AI could mine large chunks of data and use the information generated, in predicting the likelihood of outbreaks in the future. This would lend quite a helping hand to the medical world.\nBecause healthcare costs are insurmountable for the financially disadvantaged, we also wish to work towards cutting down costs in the medical sector. Our aim is centered around improving interoperability within the healthcare system. We may charge a one-time fee per country based on specific country demographics; but also will lower or remove that fee based for countries with lower GDP."
    },
    {
      q: "How would you support the healthcare industry on a global scale?",
      a: "We hope that we will be able to raise at least up to $25M as a starting point, but the monetization in other industries (financial, travel, government, education, IoT, etc.) are meant to offset the cost so we can gradually improve our overall system."
    },
    {
      q: "Will this site still exist if you reach your target funding goal?",
      a: "Yes, this site will still exist, but we would like businesses and people in general to eventually fully adopt the SmartChain technology. We envision this website to be a valuable resource point in educating users about the potential of our technology. We will keep it running in so far as it is fulfilling that purpose, and complete adoption of SmartChain will have the website run in a simultaneous manner."
    },
    {
      q: "I might not be able to donate financially. How else can I support Shoptaki’s grand vision?",
      a: "Word-of-mouth about this site significantly helps us since it can help benefit data scientists, data engineers among many others in the tech community. On top of that, we are always looking for smart people in governmental and legal organizations as well as engineering and scientific backgrounds to support us in our mission. If you know someone, we'd love to get in touch!"
    }
  ]
}