import React, { useEffect, useState } from "react";
import './Home.css';

import Partners from "./Partners";
//import SharpCard from "../../ui-comps/SharpCard";
import DonatePopUp from "../DonatePopUp";

import Smartchain_Image from "../../images/Shoptaki Data AI Network.svg";
import Smartchain_Image2 from "../../images/Shoptaki Data AI 2.png";
import Data_AI_Image from "../../images/Shoptaki Data AI.svg";
import Home_Background from "../../images/backgrounds/Home_Background.jpg";
import Arrow from "../../images/Arrow.png";

import AOS from "aos";
import "aos/dist/aos.css";
import { NUMBER_BINARY_OPERATORS, numericLiteral } from "@babel/types";

export default function Home() {

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const [dataAISlideNum, setDataAISlideNum] = useState(0);
  const [ecosystemSlideNum, setEcosystemSlideNum] = useState(0);

  return (
    <div className='Home'>
      <div className='HomeTopSection' style={{ backgroundImage: `url(${Home_Background})`, width: "100%"  }}>
        <div className='HomeHeader' data-aos='fade-up'>
          Welcome to Shoptaki's Smartchain AI technology
        </div>
      </div>
      <p /><p /><p /><p />
      <div className='DataAIAgent' data-aos="fade-right">
        <div className='DataAIAgentRight'>
          <img src={Data_AI_Image} width="520" alt=""/>
          <div className='DataAIAgentArrowRight'>
            <img src={Arrow} onClick={() => setDataAISlideNum(dataAISlideNum+1)} style={{width:"30px"}}/>
          </div>
        </div>
        <div className='DataAIAgentLeft'>
          <div className='DataAIAgentArrowLeft'>
            <img src={Arrow} onClick={() => setDataAISlideNum(dataAISlideNum+1)} style={{width:"30px", transform:"rotate(180deg)" }}/>
          </div>
          <DataAgentSlideDisplay number={dataAISlideNum}/>
        </div>
      </div>
      <div className="Ecosystem" data-aos="fade-left">
        <div style={{ display: "flex" }}>
          <div className="EcosystemLeft">
            <div className='EcosystemArrowLeft'>
              <img src={Arrow} onClick={() => setEcosystemSlideNum(ecosystemSlideNum+1)} style={{width:"30px", transform:"rotate(180deg)" }}/>
            </div>
            <img src={Smartchain_Image} width="450px" alt=""/>
          </div>
          <EcosystemSlideDisplay number={ecosystemSlideNum} />
          <div className='EcosystemArrowRight'>
            <img src={Arrow} onClick={() => setEcosystemSlideNum(ecosystemSlideNum+1)} style={{width:"30px"}}/>
          </div>
        </div>
      </div>
      <div className='SmartID' data-aos="fade-right">
        <div className='SmartIDHeader'>
          SmartID
        </div>
        <div className="SmartIDText">
          SMARTID, the byproduct of smartchain, can be considered a universal
          identity. Unlike the concept of single sign-ons popularized by
          Facebook and Google, we believe that users should have full control
          and ownership of their data. To illustrate this, let’s break down the
          process of a student applying to a university. They would require
          transcripts, medical records, financials, and so forth as part of the
          application process. Usually this process involves weeks to months of
          gathering forms and constant back-and-forth consultation, resulting in
          a painstakingly long application period. But what if this could be
          done in seconds? Faster approvals and verifications could occur
          without the time-consuming back-and-forth exchanges. Such is the
          potential of the SmartID, which would enable rapid release of data to
          other entities, within a matter of seconds. In certain cases, people
          exist without a physical identity due to which they are unable to gain
          access to basic necessities such as medical care, banking, travel and
          much more. At Shoptaki, we propose a solution to this roadblock;
          decentralizing data and giving ownership of the data to the user. In
          doing so, we would not store data ourselves since organizations would
          still be the custodians, but would allow users to have control.The
          benefits of doing so are in streamlining and accelerating data
          transactions, since data could be released to the respective entities
          based on the needs of the user. With SmartID we believe in doing
          things faster, and can ensure that data transactions will occur
          rapidly, in keeping with our vision for future-proof data
          transactions.
        </div>
      {/*<SolutionsSlideShow />*/}
    </div>
      {/* <div>
        <Spacer space="50px" />
      </div>
      <div className='last'>
        <div className={classes.bg_crystal}>
          <div className={classes.bg_darkwedge}>
            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
                paddingTop: 50,
                paddingBottom: 50,
                marginLeft: "15%",
                marginRight: "50%",
                textAlign: "left"
              }}
            >
              <h1 className={classes.Title}>Our Mission</h1>
              <p className={classes.Text} style={{ color: "white" }}>
                At Shoptaki WE DON’T STORE PERSONAL INFO, THE DATA RESIDES WITHIN
                THE RESPECTIVE ORGANIZATIONS (DECENTRALIZED DATA) SINCE THEY ARE
                CONSIDERED THE CUSTODIANS, BUT MAJOR DIFFERENCE IS THAT WE WANT
                THE PEOPLE TO HAVE CONTROL OF THEIR DATA NOT CORPORATIONS.
              </p>
            </div>
          </div>
        </div>
        {/* <div className={classes.bg3}>
          <div
            style={{
              paddingTop: 70,
              paddingBottom: 70,
              marginLeft: "50%",
              marginRight: "15%",
              textAlign: "left"
            }}
          >
            <h1 className={classes.Title}>PLACEHOLDER</h1>
            <Spacer space="3px" />
            <p className={classes.Text}>
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum."
            </p>
          </div>
        </div>
      </div> */}
      <Partners />
      <DonatePopUp />
    </div>
  );
}

function DataAIAgentSlide1() {
  return (
    <div>
      <div className='DataAIAgentHeader'>
          Data AI Agent
      </div>
      <p />
      <div className='DataAIAgentText'>
        We expect these developments to have a huge impact on many
        industries; as such, we wish to share this product with medical
        organizations on a gratuitous basis. Since misidentification,
        misdiagnosis, and similar issues continue to occur on a global
        scale, it would be in our best interests to ensure that medical
        facilities acquire this technology first.
      </div>
    </div>
  )
}

function DataAIAgentSlide2() {
  return (
    <div>
      <div className='DataAIAgentHeader'>
          Data AI Agent
      </div>
      <p />
      <div className='DataAIAgentText'>
        Even vaccine passports
        that are launched by different companies will not be able to have a
        similar global impact nor will they be able to validate data since
        they lack the same kind of interoperability Smartchain boasts.
      </div>
    </div>
  )
}

function DataAgentSlideDisplay(number) {
  if (number.number % 2 == 0) {
    return <DataAIAgentSlide1 />;
  }

  return <DataAIAgentSlide2 />;
}

function EcosystemSlide1() {
  return (
    <div>
      <div style={{ paddingLeft: "50px"}}>
        <div className="EcosystemHeader">
          Hyper-Connected Ecosystem
        </div>
        <p />
        Smartchain is the next evolution of distributed technology.
        Revolutionizing the way we see the internet and data, the
        Smartchain has similar principles to Blockchain, but is completely
        different since AI is governed in ecosystems enabling continuous
        improvement of the network.
      </div>
    </div>
  )
}

function EcosystemSlide2() {
  return (
    <div>
      <div style={{ paddingLeft: "50px"}}>
        <div className="EcosystemHeader">
          Hyper-Connected Ecosystem
        </div>
        <p />
        Through the use of data AI agents that
        continuously improve its functioning, combined with its quantum
        resistant security, SmartChain represents the best solution for
        complete integration of all data processes and applications for
        individual or business needs.
      </div>
    </div>
  )
}

function EcosystemSlide3() {
  return (
    <div>
      <div style={{ paddingLeft: "50px"}}>
        <div className="EcosystemHeader">
          Hyper-Connected Ecosystem
        </div>
        <p />
        Our long-term vision concerns the prospect of
        autonomizing data processes to meet individual or business needs
        alongside ensuring safe and future-proof data transactions. With
        Smartchain, it is possible to eliminate fragmentation, data
        disconnects, or data silos. This would help Data Scientists and
        organizations innovate cost- effectively, allowing for endless
        possibilities in applications to other industries.
      </div>
    </div>
  )
}

function EcosystemSlideDisplay(number) {
  if (number.number % 3 == 0) {
    return <EcosystemSlide1 />;
  } else if (number.number % 3 == 1) {
    return <EcosystemSlide2 />;
  }
  return <EcosystemSlide3 />;
}

