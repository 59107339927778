import React from "react";
// import { useTheme } from "@material-ui/core/styles";
import {
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillLinkedin,
} from "react-icons/ai";
import { Box, /*Container,*/ Grid, Typography, Divider,/*, Button, useMediaQuery,*/ Link } from "@material-ui/core";

function FooterCols(props) {
  return (
    <Grid item xs={12} sm={4}>
      <Link component="button" color="inherit" m={2}>
        <Typography style={{ color: "white" }}>{props.text}</Typography>
      </Link>
    </Grid>
  )
}

export default function Footer() {
  // const theme = useTheme();
  // const small = useMediaQuery("(min-width:600px)");
  // const large = useMediaQuery("(min-width:1280px)");
  // const btnStyle = large
  // ? { paddingLeft: 60, paddingRight: 60, paddingTop: 20, paddingBottom: 20 }
  // : { padding: 20 };
  const iconStyle = { color: "white", fontSize: "3em" };

  return (
    <Box
      style={{
        backgroundColor: "#0a0a14",
        position: "relative",
        left: 0,
        bottom: 0,
        width: "100%",
        marginTop: "auto",
      }}
    >
      <Grid container direction="row" justifyContent="center">
        <FooterCols text="About"/>
        <FooterCols text="Learn More"/>
        <FooterCols text="Contact Us"/>
      </Grid>
      <Divider />
      <Link href="https://www.facebook.com/shoptaki/" target="blank">
        <AiFillFacebook style={iconStyle}/>
      </Link>
      <Link href="https://twitter.com/shoptaki?lang=en" target="blank">
        <AiFillTwitterSquare style={iconStyle}/>
      </Link>
      <Link href="https://www.linkedin.com/company/shoptaki/" target="blank">
        <AiFillLinkedin style={iconStyle}/>
      </Link>
      <Divider />
      <Typography style={{ color: "white" }}>&copy; {(new Date().getFullYear())} Shoptaki, All Rights Reserved</Typography>
    </Box>
  );
}
