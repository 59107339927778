export default function About() {
    document.title = "Shoptaki AI - About"
    return(
        <div style={{padding: "30px"}}>
            <h1>Power back to the People</h1>
            <h3>Our mission at Shoptaki is to leverage technology to keep your information and virtual life in your hands. Take a look at the team working to make that possible:</h3>
            <TeamList/>
        </div>
    )
}

function TeamList() {
    return (
        <div style={{padding: "10px", display: "flex", flexDirection: "column", gap: "30px"}}>
            {people.map((item) => <Person data={item} key={item.name}/>)}
        </div>
    )
}

function Person({ data }) {
    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <img style={{width: "100px", height: "100px"}} src={imgPath(data.img_path)} alt={data.name}/>
            <div style={{display: "flex", flexDirection: "column", gap: "5px"}}>
                <div style={{textAlign: "left", paddingLeft: "20px"}}>
                    {data.name}
                </div>
                <div style={{textalign: "left", paddingLeft: "20px"}}>
                    {data.title}
                </div>
            </div>
        </div>
    )
}

let imgPath = name => require("../images/team_shots/" + name).default
// let a = require(`${baseImgPath}asnee-fernando.jpg`).default
const people = [
    {
        name: "Asnee Fernando",
        title: "Founder and CEO",
        img_path: "asnee-fernando.jpg"
    },
    {
        name: "Ruibin",
        title: "Software Engineer",
        img_path: "ruibin.jpg"
    },
    {
        name: "Michael Leonardi",
        title: "Head of Cybersecurity and Networks",
        img_path: "michael-leonardi.jpg"
    }
]
