import React from "react";
// import { Typography } from "@material-ui/core";
import logo from "../images/logo_horizontal_transparent.png";
import { Link } from "react-router-dom";

export default function Logo() {
  return (
    <Link to="/" style={{ color: "white" }}>
      <div style={{ float: "left" }}>
        <img alt="Logo" src={logo} on style={{ width: '12vw' }} />
      </div>
    </Link>
  );
}
