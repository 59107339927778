// Convention for URLs  is to omit trailing backslashes for consistency

// API URLs
export const PROXY_URL = "https://us-central1-prj-dev-smartchainai-8652.cloudfunctions.net/cors-proxy"
export const LOGIN_API_URL = "https://api.shoptaki.com"
export const BACKEND_API_URL = "https://api.smartchainai.org"

// Mailchimp API 
const MAILCHIMP_SERVER = "us13"
const MAILCHIMP_AUDIENCE_ID = "7e680195de"
const MAILCHIMP_BASE_URL = `${MAILCHIMP_SERVER}.api.mailchimp.com:443/3.0`

export const MAILCHIMP_MAIL_LIST_URL = `${MAILCHIMP_BASE_URL}/lists/${MAILCHIMP_AUDIENCE_ID}/members`