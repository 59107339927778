import { Button, Divider, Grid, LinearProgress } from "@mui/material";
import { Card, CardContent } from "@mui/material";
import React from "react";
import { useState, useEffect, createContext, useRef } from 'react'
import BackIcon from '@material-ui/icons/ArrowBack';
import ForwardIcon from '@material-ui/icons/ArrowForward';
import './SignUp.css'
import ErrorMessage from "../main-comps/Login/ErrorMessage";
import { PersonalDetails, ResidentialDetails, Verification } from './SignupSteps'
import { sendOtp } from "../main-comps/Login/OTPForm";
import * as Constants from '../constants';

function proxyReq(url) {
  return encodeURI(`${Constants.PROXY_URL}/${url}`)
}

let steps = ["User Details", "Residential Details", "Verification"] //, "Company Details", "Upload Documents"]
let lastPage = 3
let SignupContext = createContext(null)

export default function SignUp() {
  document.title = "Smartchain Signup"
  let [selected, setSelected] = useState(0)
  let [focusText, setFocusText] = useState(helperTexts["default"][0])
  // let focusText = useRef(helperTexts["default"][0])
  // let setFocusText = newVal => { focusText.current = newVal; }
  let [nextButtonDisabled, changeNextButtonState] = useState(true)
  let [error, showError] = useState({ show: false, message: "An error has occurred on our end. Please try again later." })

  const setPath = (object, path, value) => path
    .split('.')
    .reduce((o, p, i) => o[p] = path.split('.').length === ++i ? value : o[p] || {}, object)
  let [formData, setFormData] = useState(payload)
  let updateForm = (id, data) => {
    console.log("Form data", formData)
    if (id === "emails" || id === "phone_number") {
      console.log("Updating email", formData)
      let temp = formData
      temp[id] = [data]
      setFormData(temp)
    }
    else {
      let updatedPayload = formData
      setPath(updatedPayload, id, data)
      setFormData(updatedPayload)
    }
  }

  useEffect(() => {
    setFocusText(helperTexts["default"][selected])
  }, [selected])

  return (
    <SignupContext.Provider value={{ selected, setSelected, nextButtonDisabled, changeNextButtonState, error, showError, setFocusText, updateForm }}>
      <div id="Signup-Root">
        <div id="Left-Sidebar">
          <SignUpProgress />
          <InfoPanel />
        </div>
        <div id="Signup-Page-Root">
          <Card elevation={10} style={{ padding: "20px" }}>
            <Grid>
              <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ padding: "0px 0px 5px 0px" }}>
                <h1 style={{ fontSize: "230%", marginLeft: "10px" }}>{steps[selected]}</h1>
                {selected === 3 || selected === 4 ?
                  <Button
                    id="Skip-Page-Button"
                    onClick={() => { setSelected(oldPage => oldPage + 1) }} // TODO: Add implementation for skipping pages
                    endIcon={<ForwardIcon />}
                  >
                    <div>
                      <b>Skip</b>
                    </div>

                  </Button> :
                  null}
              </Grid>
              <ErrorMessage showError={showError} error={error.show} message={error.message} canDismiss={false} />
              <div style={{ padding: "15px" }} />


              <PersonalDetails />
              <ResidentialDetails />
              <Verification />
              {/* <CompanyDetails /> */}
              {/* <UploadDocuments /> */}
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              id="Nav-Button-Container"
            >
              <PreviousButton />
              <NextButton />
            </Grid>
          </Card>
        </div>
      </div>
    </SignupContext.Provider>
  )

  function SignUpProgress() {
    return (
      <Card elevation={9}>
        <CardContent id="Signup-Progress-Root">
          <h2 id="Signup-Progress-Root">Progress</h2>
          <Divider style={{ background: "black", margin: "10px" }} />

          <div id="Progress-Bar-Root">
            <SignUpProgressBar step={2} />
            <div style={{ display: "flex", flexDirection: "column" }}>
              {steps.map((item) =>
                <ProgressText
                  key={steps.indexOf(item)}
                  text={item}
                  selected={steps.indexOf(item) === selected}
                />)}
            </div>
          </div>

        </CardContent>
      </Card>
    )

    function ProgressText({ text, selected }) {
      return (
        <span style={{ padding: "10px", fontWeight: selected ? "bold" : "normal" }}>{text}</span>
      )
    }
  }

  function SignUpProgressBar() {
    return (
      <div id="Progress-Bar-Wrapper">
        <LinearProgress
          id="Progress-Bar"
          variant="determinate"
          value={(100 * ((selected + 1) / lastPage))}
        />
      </div>
    )
  }

  function InfoPanel() {
    return (
      <Card elevation={10}>
        <h2 id="Info-Panel-Header">
          Info
        </h2>
        <div id="Info-Panel-Text">
          {focusText}
        </div>
      </Card>
    )
  }

  function PreviousButton() {
    if (selected === 0) {
      return (<div></div>)
    }
    return (
      <Button
        startIcon={<BackIcon />}
        onClick={() => setSelected(oldPage => oldPage - 1)}
        style={{ borderRadius: "30px", color: "#3658A7", padding: "10px 20px" }}
        className="Nav-Button">
        <b>Previous</b>
      </Button>
    )
  }

  function NextButton() {
    let [text, changeButtonText] = useState("Next")

    function validated() {
      switch (selected) {
        case 0:
          console.debug("Verifying page 0 - Personal")
          if (formData.first_name && formData.last_name && formData.piiInfo.dob && formData.emails)
            return true
          else {
            showError({ show: true, message: "Please fill out the required fields marked with a star (*)" })
          }
          break
        case 1:
          console.log("Verifying page 1 - Residential")
          return true
        default:
          console.log("Unknown page??")
          break
      }
      return false
    }

    useEffect(() => {
      if (selected + 1 === 2) {
        changeButtonText("Submit")
      }
    }, [])
    return (selected + 1 === lastPage ? <div></div> :
      <Button
        endIcon={<ForwardIcon />}
        onClick={() => {
          if (validated()) {
            console.log(selected + 1)
            if (selected + 2 === lastPage) { // Attempting to submit everything
              updateForm("username", formData.emails[0])

              console.log("Form submitting...")
              console.log(formData)

              fetch(`${Constants.LOGIN_API_URL}/api/users/insertUser`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify(formData)
                }
              )
                .then(res => {
                  if (res.ok) {
                    setSelected(oldPage => oldPage + 1)
                    sendOtp(formData.emails[0])
                    addToMailingList()
                    return res.json()
                  }
                  else {
                    console.error("Error in submitting user information: ", res.statusText)
                  }
                })
                
                .then((response) => {
                  console.log(response)
                })
                .catch(e => {
                  console.error("InsertUser Error: ", e)
                })

            }
            else {
              setSelected(oldPage => oldPage + 1)
            }
          }
        }}
        disabled={false}//buttonState

        variant="contained"
        style={{ borderRadius: "30px", color: "white", backgroundColor: "#3658A7", padding: "10px 20px" }}
        className="Nav-Button"
      >
        <b>{text}</b>
      </Button>
    )
  }
  function addToMailingList() {
    let emailData = JSON.stringify({
      email_address: formData.emails[0],
      status: "subscribed",
      full_name: `${formData.first_name} ${formData.last_name}`
    })
    fetch(proxyReq(Constants.MAILCHIMP_MAIL_LIST_URL),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Basic " + new Buffer("anystring" + ":" + "83918ba222062c00534cf20fbd966c55-us13").toString("base64")
        },
        body: emailData,

      }
    ).then(response => {
      if (response.ok) {
        return response.json()
      }
      else {
        console.log(response.statusText)
      }
    }).then(json => {
      console.log(json)
    })
    // Not handling any errors from this call since any email added should be new, and if it's not our API catches it first.
  }


}

let helperTexts = {
  default: [
    "In this section, you need to provide information about yourself, including your name, birthday, and ways of contacting you.",
    "This section asks for information about where you live.",
    "An 8 digit code has been sent to your email from the \"User Details\" section. Enter it here so we're sure you can access your emails. In the future, you can also login this way as well.",
    "This section is about information around you and the company you work for.",
    "To identify your identity, we ask for sensitive documents to upload so that we may verify you are really you. This section is optional, but you must complete it if you want to have the full benefits of a Smartchain account."
  ],
  fName: "Your legal first name.",
  lName: "Your legal last name.",
  email: "Your personal email (you must be able to have access as this is how you will login).",
  dob: "The day, month, and year you were born.",
  phone: "Your personal phone number (must be active as this will be used for login).",
  address: "Your current residential address.",
  companyPhone: "Your work phone number.",
  website: "The primary website of your company.",
  companyEmail: "Your work email address."
}

let payload = {
  first_name: "",
  last_name: "",
  username: "",
  emails: [],
  piiInfo: {
    dob: "",
    gender: "",
    current_address: "",
    permanent_address: ""
  },
}

export { SignupContext, helperTexts }