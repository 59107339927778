import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import Copy from '@material-ui/icons/FileCopyOutlined'
import React, { useState } from 'react';
import './CryptoDonateSection.css'

import BitcoinLogo from "../images/crypto-logo/bitcoin.png";
import EthereumLogo from "../images/crypto-logo/ethereum.png";
import MoneroLogo from "../images/crypto-logo/monero.png";
import BinanceCoinLogo from "../images/crypto-logo/binance-coin.png";
import XRPLogo from "../images/crypto-logo/xrp.png";
import DogecoinLogo from "../images/crypto-logo/dogecoin.png";
import BitcoinCashLogo from "../images/crypto-logo/bitcoin-cash.png";
import LitecoinLogo from "../images/crypto-logo/litecoin.png";
import EthereumClassicLogo from "../images/crypto-logo/ethereum-classic.png";
import DAILogo from "../images/crypto-logo/dai.png";
import BitcoinGoldLogo from "../images/crypto-logo/bitcoin-gold.png";
import NEOLogo from "../images/crypto-logo/neo.png";
import ALGORANDLogo from "../images/crypto-logo/algorand.png";
import PaypalCardLogo from "../images/paypal-card-logos.png";
import PaypalLogo from "../images/paypal-logo.svg";

//Component for displaying crypto logos in an icon box format.
let [label, setLabel] = [];
let [cryptoAddress, setCryptoAddress] = [];

const cryptoList = [
    {cryptoName: "Bitcoin", cryptoSourceName: "BTC", cryptoLogo: "a", cryptoColor: "#f7931a", cryptoImage: BitcoinLogo, cryptoAddress: "bc1qqu3cfxdwgufut5gvqzuytrr98e4fue979ru6uk"},
    {cryptoName: "Ethereum", cryptoSourceName: "ETH", cryptoLogo: "a", cryptoColor: "#627eea", cryptoImage: EthereumLogo, cryptoAddress: "0x65824db009c446F13C4c3bdc5f6d919555e10D64"},
    {cryptoName: "Monero", cryptoSourceName: "XMR", cryptoLogo: "a", cryptoColor: "#ff6600", cryptoImage: MoneroLogo, cryptoAddress: "4963pxDYGcyTpqLWC82v5TZd1iKNxR43KDvtFBwmgWtF2sgHL4ETGFmTpBr2PFsLHn5D1Vw19HBdV9mgfh9iUG9ULX4eJBN"},
    {cryptoName: "Binance Coin", cryptoSourceName: "BNB", cryptoLogo: "a", cryptoColor: "#664900", cryptoImage: BinanceCoinLogo, cryptoAddress: "bnb1pdgcfwv6l3nl3e70cc2mx6z9zwa8w6fh2l63pm"},
    {cryptoName: "XRP", cryptoSourceName: "XRP", cryptoLogo: "a", cryptoColor: "#627282", cryptoImage: XRPLogo, cryptoAddress: "rJ6YU4tvGMkaqYqKwwWDrspLcAmmJp2fjn"},
    {cryptoName: "Dogecoin", cryptoSourceName: "DOGE", cryptoLogo: "a", cryptoColor: "#c3a634", cryptoImage: DogecoinLogo, cryptoAddress: "DE7cHCAE4Y4ggWcYe5AoePKCgRe81of6B7"},
    {cryptoName: "Bitcoin Cash", cryptoSourceName: "BCH", cryptoLogo: "a", cryptoColor: "#8dc351", cryptoImage: BitcoinCashLogo, cryptoAddress: "qrfv368eh0n9lfsl7j77msmvupjt2g37yqzfhqfpgx"},
    {cryptoName: "Litecoin", cryptoSourceName: "LTC", cryptoLogo: "a", cryptoColor: "#bfbbbb", cryptoImage: LitecoinLogo, cryptoAddress: "LbEVZEDWmWXxykaeQUbhfH6dPTBJLyDSSN"},
    {cryptoName: "Ethereum Classic", cryptoSourceName: "ETC", cryptoLogo: "a", cryptoColor: "#328332", cryptoImage: EthereumClassicLogo, cryptoAddress: "0xaf8139435E94dda5a78E5c7d27C3826b01bF6125"},
    {cryptoName: "DAI", cryptoSourceName: "DAI", cryptoLogo: "a", cryptoColor: "#f4b731", cryptoImage: DAILogo, cryptoAddress: "0x65824db009c446F13C4c3bdc5f6d919555e10D64"},
    {cryptoName: "Bitcoin Gold", cryptoSourceName: "BTG", cryptoLogo: "a", cryptoColor: "#eba809", cryptoImage: BitcoinCashLogo, cryptoAddress: "GSYD1xdVNveceheyNPSNqHvVrxQkjiYzAw"},
    {cryptoName: "NEO", cryptoSourceName: "NEO", cryptoLogo: "a", cryptoColor: "#58bf00", cryptoImage: NEOLogo, cryptoAddress: "ASX7ZQ8J8HUQWt64mc66XLnuK6XwWCfque"},
    {cryptoName: "Algorand", cryptoSourceName: "ALGO", cryptoLogo: "a", cryptoColor: "#888888", cryptoImage: ALGORANDLogo, cryptoAddress: "GDRQNDN2DS2VOFVFCKLGA4S4AP4JNNEO5NEAXW24DAW7Y5ZX3TKWVMKELI"}
]


export function CryptoSearchBar() {
    
    const [hover, setHover] = useState(false);
    const [search, setSearch] = useState("");
    
    return (
        <div className="CryptoSearchBar">
            <input
                className="CryptoSearchBarInput"
                type="text"
                id="header-search"
                placeholder="Search crypto"
                onChange={ e => setSearch(e.target.value) }
            />
            <CryptoSearch  cryptoList={cryptoList} search={search} setSearch={setSearch}/>
        </div>
    );
}

const cryptoPath = (name) => {
    return require(`../../node_modules/cryptocurrency-icons/svg/color/${name}.svg`).default;
}

export function CryptoSearch(props){
    const cryptoList = props.cryptoList;
    const search = props.search;
    let cryptoCount = 0;
    function filter(search, cryptoEntry) {
        if (search === "") {
            if ((cryptoEntry.cryptoName === "Bitcoin" || cryptoEntry.cryptoName === "Ethereum") || (cryptoEntry.cryptoName === "Dogecoin")) {
                return(
                    <button className="CryptoButton" style={{background: cryptoEntry.cryptoColor}} onClick={() => changeCryptoAddress(cryptoEntry.cryptoName)}>
                        {<img src={cryptoEntry.cryptoImage} className="CryptoLogo" alt=""/>}
                        <div className="CryptoButtonNameText">
                            {cryptoEntry.cryptoName}
                        </div>
                        <div className="CryptoButtonSourceName">
                            {cryptoEntry.cryptoSourceName}
                        </div>
                    </button>
                );
            }
        } else if (cryptoEntry.cryptoName.toLowerCase().includes(search.toLowerCase()) || cryptoEntry.cryptoSourceName.toLowerCase().includes(search.toLowerCase())) {
            cryptoCount++;
            if (cryptoCount <= 5) {
                return(
                    <button className="CryptoButton" style={{background: cryptoEntry.cryptoColor}} onClick={() => changeCryptoAddress(cryptoEntry.cryptoName)}>
                        {<img src={cryptoEntry.cryptoImage} className="CryptoLogo" alt=""/>}
                        <div className="CryptoButtonNameText">
                            {cryptoEntry.cryptoName}
                        </div>
                        <div className="CryptoButtonSourceName">
                            {cryptoEntry.cryptoSourceName}
                        </div>
                    </button>
                );
            }
        }
    }

    return (
        <div className="RequestsList">
            {cryptoList.map((item, i) =>
                <div className="RequestsEntry" key={i}>
                    {filter(search, item)} 
                </div>
            )}
        </div>
    );
}

export function CryptoDonateSection() {

    [label, setLabel] = useState("Payment Address");
    [cryptoAddress, setCryptoAddress] = useState("");

    return (
        <div id="Crypto-Section" style={{ width: "100%", fontSize: "150%" }}>

            <h2 align="center">
                Donate with Cryptocurrency
            </h2>
            
            <CryptoSearchBar />

            <br />

            <TextField
                type="text"
                id="Crypto-Address-Field"
                className="CryptoCopySection"
                label={label}
                variant="outlined"
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => copyToClipboard(label)}>
                                <Copy />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                defaultValue="Select a crypto icon"
                value={cryptoAddress}
            />
            <br /> <br />
        </div>
    )

}

// Edit the crypto addresses that will appear in the input field.
function changeCryptoAddress(name) {
    console.log(document.getElementById("Crypto-Address-Field"));
    let addressField = document.getElementById("Crypto-Address-Field");

    let addresses = {
        "Bitcoin": "bc1qqu3cfxdwgufut5gvqzuytrr98e4fue979ru6uk",
        "Ethereum": "0x65824db009c446F13C4c3bdc5f6d919555e10D64",
        "Monero": "4963pxDYGcyTpqLWC82v5TZd1iKNxR43KDvtFBwmgWtF2sgHL4ETGFmTpBr2PFsLHn5D1Vw19HBdV9mgfh9iUG9ULX4eJBN",
        "Binance Coin": "bnb1pdgcfwv6l3nl3e70cc2mx6z9zwa8w6fh2l63pm",
        "XRP": "rJ6YU4tvGMkaqYqKwwWDrspLcAmmJp2fjn",
        "Dogecoin": "DE7cHCAE4Y4ggWcYe5AoePKCgRe81of6B7",
        "Bitcoin Cash": "qrfv368eh0n9lfsl7j77msmvupjt2g37yqzfhqfpgx",
        "Litecoin": "LbEVZEDWmWXxykaeQUbhfH6dPTBJLyDSSN",
        "Ethereum Classic": "0xaf8139435E94dda5a78E5c7d27C3826b01bF6125",
        "DAI": "0x65824db009c446F13C4c3bdc5f6d919555e10D64",
        "Bitcoin Gold": "GSYD1xdVNveceheyNPSNqHvVrxQkjiYzAw",
        "NEO": "ASX7ZQ8J8HUQWt64mc66XLnuK6XwWCfque",
        "Algorand": "GDRQNDN2DS2VOFVFCKLGA4S4AP4JNNEO5NEAXW24DAW7Y5ZX3TKWVMKELI"
    }

    setLabel(name + " Address");
    setCryptoAddress(addresses[name]);
    // setCryptoAddress(addresses[name]);
    // addressField.value = addresses[name]

    // var element = document.getElementById("Crypto-Buttons");
    // var scrollAmount = element.offsetHeight - 50;
    // window.scrollTo(0, scrollAmount);
}

//Copy the content in the input field to the user's clipboard.
function copyToClipboard(label) {
    let copyText = document.getElementById("Crypto-Address-Field");
    if (label === "Payment Address") {
        return;
    }
    
    // This method of copying is deprecated, but it works fine
    copyText.select();
    document.execCommand("copy");
    // showSnackbar({ show: true, message: "Copied!" })
}