import { useState, useEffect, useContext, forwardRef, useRef } from 'react';
import React from 'react';
import TextField from '@mui/material/TextField';
import { IMaskInput } from 'react-imask';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { errorCodes, VerificationStage, sendOTP } from '../main-comps/Login/OTPForm';
import { Button, Checkbox, FormControlLabel, Grid, Select } from "@mui/material";
import { SignupContext, helperTexts } from './SignUp';
import { MenuItem } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles'
const countries = require('./country_states_and_codes.json').countries;


function BaseFormStep({ children, index }) {
  let { changeNextButtonState, selected } = useContext(SignupContext)
  let newChildren = React.Children.map(children, (child, index) => React.cloneElement(child, { index: index }))

  if (index !== selected) {
    return null
  }
  else return (
    <form style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "20px" }} >
      {/* {React.cloneElement(children, {onHandleChange: onHandleChange})} */}
      {newChildren}
    </form>
  )
}

const Field = forwardRef(({ label, fullWidth, example, helperKey, small, width, req = false, type, params, icon, index, id, key, specialInput, setData }, ref) => {
  let { setFocusText, selected, updateForm } = useContext(SignupContext)
  const updateFocusText = () => setFocusText(helperTexts[helperKey])
  let newInputParams = { startAdornment: icon }
  if (params) {
    newInputParams = {
      ...params.InputProps,
      ...newInputParams,
    }
  }

  let [errorText, setErrorText] = useState('')
  let [fieldText, changeFieldText] = useState("")

  return (
    <TextField
      key={key}
      id={id}
      fullWidth={fullWidth}
      variant="outlined"
      label={label}
      type={type}
      value={fieldText}
      inputRef={ref}
      InputProps={{ ...newInputParams, inputComponent: specialInput, inputProps: { ...params?.inputProps, index: index }, index: index }}
      error={!!errorText}
      helperText={errorText}
      style={{ flexGrow: small ? 0 : 1, width: width }}
      onChange={e => {
        changeFieldText(e.target.value)
        updateForm(id, e.target.value)
      }}
      onFocus={e => {
        // e.preventDefault()
        // updateFocusText()
      }}
      onBlur={e => {        
        if (req) {
          setErrorText(fieldText?.trim()?.length === 0 ? "This field is required" : "")
        }
        setFocusText(helperTexts["default"][selected])
      }}
      placeholder={example}
      InputLabelProps={{ shrink: true, required: req }} />
  )
})

function CheckBox({ checkboxId, text }) {
  return (
    <FormControlLabel
      control={<Checkbox key={checkboxId}
        checked={undefined}
        onChange={e => {

        }}/>
      }
        key={checkboxId}
        label={text}
      />
  )
}

const countriesByPhone = countries
  .filter((val, index, self) => self.map(i => i["phone_code"]).indexOf(val["phone_code"]) === index)
  .sort((a, b) => parseInt(a["phone_code"].slice(1)) - parseInt(b["phone_code"].slice(1)))

const FlagIcon = props =>
  <img
    loading="lazy"
    src={`https://flagcdn.com/${props.code}.svg`}
    width="30"
    alt="" />

function PersonalDetails() {
  return (
    <BaseFormStep index={0} >
      <Field label="First name" example="John" helperKey="fName" req id="first_name" />
      <Field label="Last name" example="Smith" helperKey="lName" req id="last_name"/>
      <Field label="Date of Birth" example="MM/DD/YYYY" helperKey="dob" small req type="date" defaultValue={"04-03-2000"} id="piiInfo.dob"/>
      <Field label="Email Address" example="example@email.com" helperKey="email" req id="emails"/>
      <PhoneInput />

    </BaseFormStep>
  )

  function PhoneInput({ setData, index }) {
    let phone2Flag = input => input ? countriesByPhone.find(a => a["phone_code"] === input)["alpha_2"].toLowerCase() : null

    const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
      const { onChange, ...other } = props;
      return (
        <IMaskInput
          {...other}
          mask="(#00) 000-0000"
          definitions={{
            '#': /[1-9]/,
          }}
          inputRef={ref}
          onAccept={(value) => onChange({ target: { name: props.name, value } })}
          overwrite
        />
      );
    });
    let [phoneCode, setPhoneCode] = useState('+1')
    let handlePhoneCodeChange = e => setPhoneCode(e.target.value)
    let classes = makeStyles(t => ({ icon: { left: 0 } }))()

    const DropDownCC = () =>
      <Select
        value={phoneCode}
        onChange={handlePhoneCodeChange}
        variant="filled"
        sx={{ bgcolor: 'transparent', height: 40, borderRadius: 2, width: 115, mr: 1 }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 270,
              width: 250,
              borderRadius: 5
            }
          }
        }}
        disableUnderline
        classes={{ icon: classes.icon }}
        renderValue={val => <div style={{ display: "flex", flexDirection: "row", padding: "5px", gap: "10px", alignItems: "center", paddingBottom: "22px", paddingLeft: "15px" }}><FlagIcon code={phone2Flag(val)} />{val}</div>}
      >
        {countriesByPhone.map(i =>
          <MenuItem key={i["alpha_2"]} style={{ display: "flex", flexDirection: "row", padding: "5px", gap: "10px" }} value={i["phone_code"]}>
            <FlagIcon code={i["alpha_2"].toLowerCase()} />
            {i.short_name || (i.name.length > 13 ? i.name.slice(0, 13) + "..." : i.name)} <div style={{ marginLeft: "auto" }}>{i["phone_code"]}</div>
          </MenuItem>)}
      </Select>
    return (
      <Field
        label="Phone Number"
        example="123-456-7890"
        helperKey="phone"
        small width="40%"
        id="phone_number"
        specialInput={TextMaskCustom}
        setData={setData}
        index={index}
        icon={<DropDownCC />} />
    )
  }
}

function ResidentialDetails() {
  let [country, setCountry] = useState(null)
  let [province, setProvince] = useState(null)
  let changeCountry = newCountry => {
    setCountry(newCountry)
    setProvince(null)
  }
  let filterOptions = createFilterOptions({
    stringify: option => `${option.name} ${option.alpha_3} ${option.number_code}`
  })
  return (
    <BaseFormStep index={1} >
      <Field label="Address" example="15 Evergreen Terrace" helperKey="address" width="45%" id="permanent_address"/>
      <CountryField changeCountry={changeCountry} />
      <StateField />
      <Field label="City" example="Springfield" helperKey="city" id="city"/>
      <Field label="Zip Code" example="012345" helperKey="zip" small width="8 ch" id="postal_code"/>
      <CheckBox text={<div>By creating a SmartID account, you adhere to our Terms of Service, which can be viewed <a href='smartchainai.org'>here</a></div>}/>
    </BaseFormStep>
  )

  function CountryField({ setData, index, changeCountry }) {
    return <Autocomplete
      style={{ width: "45%" }}
      options={countries}
      getOptionLabel={option => option.name || ""}
      filterOptions={filterOptions}
      onChange={(_event, newVal) => { changeCountry(newVal?.name) }}
      renderOption={(props, option) =>
        <div key={option.name} {...props} style={{ display: "flex", flexDirection: "row", padding: "5px", gap: "10px" }}>
          <FlagIcon code={option.alpha_2.toLowerCase()} />
          <div>
            {option.name}
          </div>
        </div>
      }
      renderInput={params =>
        <Field
          id="country"
          setData={setData}
          index={index}
          fullWidth
          icon={!country ? <div /> :
            <FlagIcon code={countries.filter(a => a.name === country)[0]?.["alpha_2"].toLowerCase()} />
          } params={params} label="Country" example="USA" helperKey="country" />
      }
    />
  }

  function StateField({ setData, index }) {
    return <Autocomplete
      style={{ width: "30%" }}
      value={province}
      onChange={(e, newVal) => setProvince(newVal)}
      options={countries.filter(a => a.name === country)[0]?.["states_list"] || []}
      renderOption={(props, option) => <div key={option} {...props}>{option}</div>}
      renderInput={params =>
        <Field setData={setData} index={index} params={params} label="State/Province" fullWidth example="Illinois" helperKey="state" id="state"/>
      }
    />
  }
}

function CompanyDetails() {
  return (
    <BaseFormStep index={3}>
      <Field label="Job Title" example="Software Developer" />
      <Field label="Company Name" example="Tech Company Inc." />
      <Field label="Company Email" example="tech@company.org" />
      <Field label="Company URL" example="techcompany.org" />
      <Field label="Company Contact Number" example="+0 123-456-7890" />
    </BaseFormStep>
  )
}

function Verification() {
  let { selected } = useContext(SignupContext)
  if (selected !== 2) {
    return null
  }
  return (
    <VerificationStage />
  )
}

function UploadDocuments() {
  let { selected } = useContext(SignupContext)
  if (selected !== 4) {
    return null
  }
  return (
    <Grid container direction="column">
      <div style={{ marginBottom: "20px" }}>To verify your personal information and get access to the full smartchain network, upload the following documents. No file may exceed a size of 20 MB.</div>
      <DocumentItem name="Passport" />
      <DocumentItem name="Driver's License" />
    </Grid>
  )

  function DocumentItem({ name }) {
    return (
      <Grid container direction="row" alignItems="center" spacing={1} style={{ padding: "15px" }}>
        <div style={{ margin: "10px 15px" }}><b>{name}</b></div>
        <Button
          variant="outlined"
          onClick={() => { }}
          style={{ color: "#3658A7" }}
        >
          Upload
        </Button>
      </Grid>
    )
  }
}

export { PersonalDetails, ResidentialDetails, Verification }