import { Dialog, DialogContent, DialogTitle, Tab, Tabs } from "@material-ui/core";
import React, {useState} from "react";
import OTPForm from "./OTPForm";
import {useHistory} from 'react-router-dom'
import FaceIcon from '@material-ui/icons/Face';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import BiometricAuthForm from "./BiometricAuthForm"
import {formHidden} from './OTPForm'

export let [loginOpen, setLoginShow] = []
export default function LoginModal() {
  [loginOpen, setLoginShow] = useState(false)
  const history = useHistory()

  let [currentTab, changeTab] = useState(0)

  let titles = ["Login", "Enter the OTP code from your email"]

  const onClose = () =>{
    setLoginShow(false)
    history.goBack()
  }

  return (
    <Dialog
      open={loginOpen}
      onClose={onClose}
      style={{borderRadius: "10"}}>
        <DialogTitle id="login-dialog-title"><b>{!formHidden ? titles[0] : titles[1]}</b></DialogTitle>
          <DialogContent>
            <Tabs value={currentTab} onChange={(event, newVal) => changeTab(newVal)} style={{width: "100%"}}>
              <Tab label="One Time Pass" icon={<AvTimerIcon/>}/>
              <Tab label="Biometric Authentication" icon={<FaceIcon/>}/>
            </Tabs>
            {currentTab === 0 ? <OTPForm /> : null }
            {currentTab === 1 ? <BiometricAuthForm /> : null }
          </DialogContent>
    </Dialog>
  );
}
